import { useState } from 'react'

const initialData = {
  name: '',
  email: '',
  message: '',
}

export const Contact = (props) => {
  const [data, setData] = useState(initialData)
  const [state, setState] = useState()

  const handleChange = (e) => {
    const { name, value } = e.target
    setData((prevState) => ({ ...prevState, [name]: value }))
  }

  const clearState = () => setData({ ...initialData })

  const handleSubmit = (e) => {
    e.preventDefault()

    setState('sending')
    fetch('https://api.appointmint.pt/landing/contact-us', {
    // fetch('http://localhost:5000/landing/contact-us', {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'content-type': 'application/json',
      },

    })
    .then(
      (result) => {
        console.log(result)
        if(result.ok) {
          setState('sent')
          clearState()
        } else {
          setState('error')
        }
      },
      (error) => {
        console.log(error)
        setState('error')
      }
    )
  }
  return (
    <div id='hcontact'>
      <div id='contact'>
        <div className='container'>
          <div className='col-md-8'>
            <div className='row'>
              <div className='section-title'>
                <h2>{props.data?.title ?? 'Get In Touch'}</h2>
                <p>
                  {props.data?.message ?? 
                    'Please fill out the form below to send us an email and we will get back to you as soon as possible.'}
                </p>
              </div>
              <form name='sentMessage' validate onSubmit={handleSubmit}>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='text'
                        id='name'
                        name='name'
                        className='form-control'
                        placeholder={props.data?.namePlaceholder ?? 'Name'}
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='email'
                        id='email'
                        name='email'
                        className='form-control'
                        placeholder={props.data?.emailPlaceholder ?? 'Email'}
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                </div>
                <div className='form-group'>
                  <textarea
                    name='message'
                    id='message'
                    className='form-control'
                    rows='4'
                    placeholder={props.data?.messagePlaceholder ?? 'Message'}
                    required
                    onChange={handleChange}
                  ></textarea>
                  <p className='help-block text-danger'></p>
                </div>
                <div id='success'></div>
                <button type='submit' className='btn btn-custom btn-lg' disabled={['sending', 'sent'].includes(state)}>
                  {state === 'sent' 
                    ? <>{props.data?.sent ?? 'Sent '}<i className="fa fa-check"></i></>
                    : props.data?.sendButton ?? 'Send Message'}
                </button>
              </form>
            </div>
          </div>
          <div className='col-md-3 col-md-offset-1 contact-info'>
            {props.data?.address && (<div className='contact-item'>
              <h3>{props.data?.contactInfo ?? 'Contact Info'}</h3>
              <p>
                <span>
                  <i className='fa fa-map-marker'></i> {props.data?.addressTitle ?? 'Address'}
                </span>
                {props.data.address}
              </p>
            </div>)}
            {props.data?.phone && (<div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-phone'></i> {props.data?.phoneTitle ?? 'Phone'}
                </span>{' '}
                {props.data.phone}
              </p>
            </div>)}
            {props.data?.email && (<div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-envelope-o'></i> Email
                </span>{' '}
                {props.data ? props.data.email : 'loading'}
              </p>
            </div>)}
          </div>
          <div className='col-md-12'>
            <div className='row'>
              <div className='social'>
                <ul>
                  {props.data?.facebook && (<li>
                    <a href={props.data.facebook}>
                      <i className='fa fa-facebook'></i>
                    </a>
                  </li>)}
                  {props.data?.twitter && (<li>
                    <a href={props.data.twitter}>
                      <i className='fa fa-twitter'></i>
                    </a>
                  </li>)}
                  {props.data?.youtube && (<li>
                    <a href={props.data.youtube}>
                      <i className='fa fa-youtube'></i>
                    </a>
                  </li>)}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
