import ParticlesBg from "particles-bg";
import { AppNameIcon } from "./icons/AppNameIcon";

//#08b180
//#209b97
//#49b2e5
export const Header = (props) => {
  return (
    <header id='header'>
      <div className='intro'>
        <ParticlesBg color={["#08b180", "#209b97", "#49b2e5"]} type="circle" bg={{zIndex: 0, position:"absolute", top:0}} />
        <div className='overlay'>
          <div className='col-md-8 col-md-offset-2 intro-text'>
            <h1>
              {/* {props.data ? props.data.title : 'Loading'} */}
                <AppNameIcon style={{fill: 'white'}}/>
              <span></span>
            </h1>
            <p>{props.data ? props.data.paragraph : 'Loading'}</p>
            <a
              href='#features'
              className='btn btn-custom btn-lg page-scroll btn-white'
            >
              {props.data?.learnMore ?? 'Learn More'}
            </a>{' '}
          </div>
        </div>
      </div>
    </header>
  )
}
