export const AppNameIcon = (props) => {
    return (
        <svg className="AppNameIcon" version="1.1" id="AppNameIcon" viewBox="0 0 1000 170.8" {...props}>
            <g>
                <path d="M948.08,98.22c-1.32,0.56-6.9,2.46-7.07,2.43c0.2-0.17,8.16-13.76-1.24-35.56c-0.71,0.47-4.64,3.37-4.78,3.38
                c-4.03-18.03-17.08-29-19.59-31.12c-0.1-0.08-0.25-0.14-0.34-0.14c-0.1,0-0.25,0.06-0.34,0.14c-2.5,2.12-15.55,13.09-19.59,31.12
                c-0.14-0.01-4.07-2.91-4.78-3.38c-9.4,21.8-1.43,35.39-1.24,35.56c-0.17,0.03-5.75-1.87-7.07-2.43
                c1.4,18.64,14.29,27.32,27.03,33.21c0.96,0.44,1.83,0.84,2.69,1.22l3.3-75.51l3.3,75.51c0.91-0.35,1.73-0.78,2.69-1.22
                C933.8,125.54,946.68,116.86,948.08,98.22z"/>
                <g>
                    <path d="M915.06,169.87c-46.83,0-84.93-38.1-84.93-84.93S868.23,0,915.06,0S1000,38.1,1000,84.93
                        S961.9,169.87,915.06,169.87z M915.06,14.51c-38.83,0-70.43,31.59-70.43,70.43s31.59,70.43,70.43,70.43
                        c38.83,0,70.43-31.59,70.43-70.43S953.9,14.51,915.06,14.51z"/>
                </g>
            </g>
            <g>
                <path d="M115.13,116.97v36.15c0,4.44-3.49,7.93-7.93,7.93c-4.28,0-7.93-3.49-7.93-7.93V75.9
                c0-22.67,15.06-36.15,32.98-36.15c18.08,0,32.82,13.64,32.82,36.15v13.48c0,21.4-13.79,34.41-31.08,34.41
                C126.38,123.79,120.99,121.41,115.13,116.97z M149.22,74.79c0-13-7.61-19.98-16.97-19.98s-17.13,6.98-17.13,19.98v16.33
                c0,9.67,7.77,17.6,17.13,17.6s16.97-6.98,16.97-19.98V74.79z"/>
                <path d="M195.68,116.97v36.15c0,4.44-3.49,7.93-7.93,7.93c-4.28,0-7.93-3.49-7.93-7.93V75.9
                c0-22.67,15.06-36.15,32.98-36.15c18.08,0,32.82,13.64,32.82,36.15v13.48c0,21.4-13.79,34.41-31.08,34.41
                C206.94,123.79,201.55,121.41,195.68,116.97z M229.77,74.79c0-13-7.61-19.98-16.97-19.98c-9.36,0-17.13,6.98-17.13,19.98v16.33
                c0,9.67,7.77,17.6,17.13,17.6c9.36,0,16.97-6.98,16.97-19.98V74.79z"/>
                <path d="M325.08,87.79c0,22.36-14.75,36-32.82,36c-18.23,0-32.98-13.64-32.98-36V75.74c0-22.36,14.75-36,32.98-36
                c18.08,0,32.82,13.64,32.82,36V87.79z M309.22,74.79c0-13-7.61-19.98-16.97-19.98c-9.51,0-17.13,6.98-17.13,19.98v13.96
                c0,13,7.61,19.98,17.13,19.98c9.36,0,16.97-6.98,16.97-19.98V74.79z"/>
                <path d="M349.18,30.23c-4.76,0-8.72-3.96-8.72-8.72s3.96-8.72,8.72-8.72c4.92,0,8.72,3.96,8.72,8.72
                S354.1,30.23,349.18,30.23z M341.26,113.48V50.05c0-4.44,3.65-7.93,7.93-7.93c4.44,0,7.93,3.49,7.93,7.93v63.43
                c0,4.44-3.49,7.93-7.93,7.93C344.9,121.41,341.26,117.92,341.26,113.48z"/>
                <path d="M424.35,113.48V74.15c0-12.69-7.61-19.35-16.97-19.35c-9.51,0-17.12,6.66-17.12,19.35v39.33
                c0,4.44-3.49,7.93-7.93,7.93c-4.44,0-7.93-3.49-7.93-7.93V75.74c0-22.36,14.75-36,32.98-36c18.08,0,32.83,13.64,32.83,36v37.74
                c0,4.44-3.65,7.93-7.93,7.93C427.84,121.41,424.35,117.92,424.35,113.48z"/>
                <path d="M493.65,106.34c4.13,0,7.61,3.33,7.61,7.61c0,4.12-3.49,7.45-7.61,7.45h-7.13c-15.7,0-25.21-9.51-25.21-25.21
                V57.19h-4.12c-4.28,0-7.61-3.33-7.61-7.45c0-4.28,3.33-7.61,7.61-7.61h4.12V26.9c0-4.44,3.49-8.09,7.77-8.09
                c4.44,0,8.09,3.49,8.09,7.93v15.38h16.33c4.28,0,7.61,3.33,7.61,7.61c0,4.13-3.33,7.45-7.61,7.45h-16.33v37.58
                c0,8.09,3.33,11.58,11.42,11.58H493.65z"/>
                <path d="M617.02,74.94v38.53c0,4.44-3.65,7.93-7.93,7.93c-4.44,0-7.93-3.49-7.93-7.93V73.36
                c0-12.21-7.29-18.55-16.17-18.55c-3.97,0-7.61,1.43-10.47,3.81c1.9,3.96,2.85,11.57,2.85,16.33v38.53c0,4.44-3.49,7.93-7.93,7.93
                c-4.28,0-7.93-3.49-7.93-7.93V73.36c0-12.21-7.13-18.55-16.17-18.55c-8.88,0-16.17,6.34-16.17,18.55v40.12
                c0,4.44-3.49,7.93-7.93,7.93s-7.93-3.49-7.93-7.93V74.94c0-21.88,14.43-35.2,32.03-35.2c7.77,0,15.22,3.01,20.77,7.61
                c2.54-2.38,8.41-7.61,18.87-7.61C602.59,39.74,617.02,53.06,617.02,74.94z"/>
                <path d="M642.08,30.23c-4.76,0-8.72-3.96-8.72-8.72s3.96-8.72,8.72-8.72c4.92,0,8.72,3.96,8.72,8.72
                S646.99,30.23,642.08,30.23z M634.15,113.48V50.05c0-4.44,3.65-7.93,7.93-7.93c4.44,0,7.93,3.49,7.93,7.93v63.43
                c0,4.44-3.49,7.93-7.93,7.93C637.8,121.41,634.15,117.92,634.15,113.48z"/>
                <path d="M717.24,113.48V74.15c0-12.69-7.61-19.35-16.97-19.35c-9.51,0-17.13,6.66-17.13,19.35v39.33
                c0,4.44-3.49,7.93-7.93,7.93c-4.44,0-7.93-3.49-7.93-7.93V75.74c0-22.36,14.75-36,32.98-36c18.08,0,32.82,13.64,32.82,36v37.74
                c0,4.44-3.65,7.93-7.93,7.93C720.73,121.41,717.24,117.92,717.24,113.48z"/>
                <path d="M786.54,106.34c4.13,0,7.61,3.33,7.61,7.61c0,4.12-3.49,7.45-7.61,7.45h-7.13c-15.7,0-25.21-9.51-25.21-25.21
                V57.19h-4.12c-4.28,0-7.61-3.33-7.61-7.45c0-4.28,3.33-7.61,7.61-7.61h4.12V26.9c0-4.44,3.49-8.09,7.77-8.09
                c4.44,0,8.09,3.49,8.09,7.93v15.38h16.33c4.28,0,7.61,3.33,7.61,7.61c0,4.13-3.33,7.45-7.61,7.45h-16.33v37.58
                c0,8.09,3.33,11.58,11.42,11.58H786.54z"/>
                <path d="M88.01,110.15L52.17,14.21c-1.27-3.17-4.44-5.39-7.77-5.39c-4.12,0-6.82,2.54-7.93,5.39L0.63,110.15
                C0.16,111.42,0,112.21,0,113.16c0,4.6,4.12,8.24,8.56,8.24c3.17,0,6.5-1.9,7.77-5.39l10.76-29.65l0,0l0.01-0.02l4.14-11.4
                L44.4,39.11l17.15,47.26l0,0l10.76,29.65c1.43,3.33,4.44,5.39,7.77,5.39c5.07,0,8.41-4.12,8.41-8.4
                C88.48,112.05,88.33,111.1,88.01,110.15z"/>
            </g>
        </svg>
    )
}
