import { AppNameIcon } from './icons/AppNameIcon'
import { AppIcon } from './icons/AppIcon'

export const Navigation = (props) => {
  return (
    <nav id='menu' className='navbar navbar-default navbar-fixed-top'>
      <div className='container'>
        <div className='navbar-header'>
          <button
            type='button'
            className='navbar-toggle collapsed'
            data-toggle='collapse'
            data-target='#bs-example-navbar-collapse-1'
          >
            {' '}
            <span className='sr-only'>Toggle navigation</span>{' '}
            <span className='icon-bar'></span>{' '}
            <span className='icon-bar'></span>{' '}
            <span className='icon-bar'></span>{' '}
          </button>
          <div className='w-100'>
            <a className='navbar-brand page-scroll' style={{padding: 0, display: 'flex', alignItems: 'center'}} href='#page-top'>
              <AppNameIcon/>
              <AppIcon/>
            </a>{' '}
          
            <a
              href='https://app.appointmint.pt'
              className='btn btn-custom btn-sm navbar-right burger-on'
            >
              {props.data?.enter ?? 'Login'}
            </a>
          </div>
        </div>

        <div
          className='collapse navbar-collapse'
          id='bs-example-navbar-collapse-1'
        >
          <ul className='nav navbar-nav navbar-right'>
            <li>
              <a href='#features' className='page-scroll'>
                {props.data?.features ?? 'Features'}
              </a>
            </li>
            <li>
              <a href='#about' className='page-scroll'>
                {props.data?.about ?? 'About'}
              </a>
            </li>
            <li>
              <a href='#hcontact' className='page-scroll'>
                {props.data?.contact ?? 'Contact'}
              </a>
            </li>
            <a
              href='https://app.appointmint.pt'
              className='btn btn-custom btn-sm navbar-right'
            >
              {props.data?.enter ?? 'Login'}
            </a>
            {/* <li>
              <a href='#services' className='page-scroll'>
                Services
              </a>
            </li>
            <li>
              <a href='#portfolio' className='page-scroll'>
                Gallery
              </a>
            </li>
            <li>
              <a href='#testimonials' className='page-scroll'>
                Testimonials
              </a>
            </li> */}
          </ul>
        </div>
      </div>
    </nav>
  )
}
