export const AppIcon = (props) => (
    <svg className="AppIcon" version="1.1" id="AppIcon" viewBox="0 0 500 500" {...props}>
        <g>
            <path d="M347.18,289.11c-3.89,1.65-20.3,7.24-20.81,7.16c0.59-0.51,24.02-40.5-3.64-104.67
                c-2.09,1.38-13.67,9.92-14.07,9.96c-11.88-53.08-50.29-85.35-57.65-91.6c-0.28-0.24-0.73-0.42-1.01-0.42
                c-0.29,0-0.73,0.18-1.01,0.42c-7.37,6.24-45.78,38.52-57.65,91.6c-0.4-0.04-11.98-8.58-14.07-9.96
                c-27.66,64.17-4.22,104.15-3.64,104.67c-0.51,0.08-16.93-5.52-20.81-7.16c4.12,54.86,42.05,80.42,79.55,97.75
                c2.81,1.3,5.38,2.46,7.91,3.6L250,168.2l9.72,222.26c2.69-1.04,5.1-2.3,7.91-3.6C305.13,369.52,343.06,343.96,347.18,289.11z"/>
            <g>
                <path d="M250,500C112.14,500,0,387.85,0,250S112.14,0,250,0S500,112.15,500,250S387.85,500,250,500z M250,42.7
                    c-114.31,0-207.3,92.99-207.3,207.3S135.69,457.3,250,457.3S457.31,364.31,457.31,250S364.3,42.7,250,42.7z"/>
            </g>
        </g>
    </svg>
);
