export const About = (props) => {
  return (
    <div id='about'>
      <div className='container'>
        <div className='row'>
          <div className='col-xs-12 col-md-6' style={{overflow: 'hidden'}}>
            {' '}
            <img style={{transform: 'scale(1.5)'}} src='img/Mockup_Devices1.png' className='img-responsive' alt='Nunca foi tão fácil gerir o seu negócio, a partir de qualquer lugar, seja no computador, tablet ou smartphone.' />{' '}
          </div>
          <div className='col-xs-12 col-md-6'>
            <div className='about-text'>
              <h2>{props.data?.title ?? 'About Us'}</h2>
              <p>{props.data ? props.data.paragraph : 'loading...'}</p>
              <h3>{props.data?.whyTitle ?? 'Why Choose Us?'}</h3>
              <div className='list-style'>
                <div className='col-lg-6 col-sm-6 col-xs-12'>
                  <ul>
                    {props.data
                      ? props.data.Why.map((d, i) => (
                          <li key={`${d}-${i}`}>{d}</li>
                        ))
                      : 'loading'}
                  </ul>
                </div>
                <div className='col-lg-6 col-sm-6 col-xs-12'>
                  <ul>
                    {props.data
                      ? props.data.Why2.map((d, i) => (
                          <li key={`${d}-${i}`}> {d}</li>
                        ))
                      : 'loading'}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
